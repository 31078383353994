<template>
  <nav class="navbar navbar-expand-lg bg-light shadow">
    <div class="container d-md-flex mx-auto">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand justify-content-center" href="/">
        <img src="./assets/img/hb_coffee_logo.webp" width="50" height="50" alt="Logo">
      </a>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav w-100 justify-content-end">
          <li class="nav-item">
            <a class="nav-link" :class="{active: $route.name === 'home'}" href="/">Kezdőlap</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{active: $route.name === 'portfolio'}" href="/portfolio">Portfolió</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{active: $route.name === 'specs'}" href="/specs">Specifikáció</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{active: $route.name === 'servers'}" href="/servers">Szerverek</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-4 d-md-inline nav-social" :class="{active: $route.name === 'stats'}" href="/stats" title="Skyblock Stats" alt="Skyblock Stats"><i class="fa-solid fa-chart-simple"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-4 d-md-inline nav-social" href="https://steamcommunity.com/id/harmatosbikafing" target="_blank" title="Steam" alt="Steam"><i class="fab fa-steam"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-4 d-md-inline nav-social" href="https://github.com/spatrik2001" target="_blank" title="Github" alt="Github"><i class="fab fa-github"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div id="scrollToTop" v-show="isVisible" @click="scrollToTop()">
    <a href="#" aria-label="scrollTop">
      <i class="fa-solid fa-angle-up"></i>
    </a>
  </div>
  <router-view/>
  <footer-component class="mt-auto"/>
</template>

<style>
  html, body {
      height: 100%;
  }

  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>

<script>
  import FooterComponent from './components/FooterComponent.vue';

  export default {
    data() {
      return {
        isVisible: false
      }
    },

    components: {
      FooterComponent
    },

    mounted() {
      window.addEventListener("scroll", () => {
        this.isVisible = window.scrollY > 200;
      });
    },

    methods: {
      scrollToTop() {
        window.scrollTo(0,0);
      }
    }
  }
</script>