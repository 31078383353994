<template>
  <div class="home">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-0">
          <div class="header-container" :style="{ backgroundImage: `url(${require('../assets/img/home-firewatch-background.webp')})`}">
            <div class="container h-100">
              <div class="header-text mx-auto">
                <h3>Üdvözöllek az oldalon!</h3>
                <p>Kattints a gombra a folytatáshoz!</p>
                <button
                  type="button"
                  @click="scroll"
                  class="btn btn-warning rounded-circle btn-lg"
                  aria-label="new-design_button"
                >
                  <i class="fas fa-chevron-down mt-2 fs-3"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="latest-section-row">
      <div class="container card-separator">
        <h2 class="section-title">Legutóbbi projektek</h2>
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-4"
            v-for="(repository, index) in repositories.slice(0, 3)"
            :key="repository.id"
          >
            <div v-if="index < 3">
              <latestRepo
                :title="repository.name"
                :urlLink="repository.html_url"
                :description="repository.description"
                :updated="repository.pushed_at"
                :stars="repository.stargazers_count"
                :watchers="repository.watchers_count"
              >
              </latestRepo>
            </div>
          </div>
          <a class="btn latest-repo_btn" href="/portfolio">További projektek</a>
        </div>
      </div>
    </div>
    <div class="mobile-social-row"></div>
    <div class="about-row">
      <div class="container py-5" id="about">
        <div class="row d-flex">
          <div class="col-md-2 d-block mx-auto">
            <img
              class="img-fluid rounded-circle"
              src="https://avatars.githubusercontent.com/u/70636500?v=4"
              width="150" height="150"
              alt="Rólam"
            />
          </div>
          <div class="col-md-9 my-auto">
            <p class="fs-4 fw-bold">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio
              ipsum exercitationem ipsa eius temporibus sint vel facilis
              consequuntur dolorem dicta nam ab beatae, et cum modi. Ipsam dolores
              corrupti quam?
            </p>
            <div class="row row-cols-1 row-cols-md-6 g-4 mb-5 d-md-flex">
              <div class="col-md-6 hstack gap-2">
                <span class="badge text-bg-warning home-badge">VueJS</span>
                <span class="badge text-bg-warning">NodeJS</span>
                <span class="badge text-bg-warning">Angular</span>
                <span class="badge text-bg-danger">Danger</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import github from "../services/githubservice";
import latestRepo from "../components/LatestCardComponent.vue";

export default {
  data() {
    return {
      repositories: [],
    };
  },
  components: {
    latestRepo,
  },

  methods: {
    scroll() {
      const element = document.getElementById("about");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
  async mounted() {
    await github.getLatestRepositories().then((response) => {
      this.repositories = response;
    });
  },
  created() {
    document.title = "harmatosbikafing · Kezdőlap";
  },
};
</script>