import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const username = username;

const routes = [
  {
    path: '/', name: 'home', component: HomeView
  },
  {
    path: '/portfolio', name: 'portfolio',
    component: () => import('../views/PortfolioView.vue')
  },
  {
    path: '/specs', name: 'specs',
    component: () => import('../views/SpecView.vue')
  },
  {
    path: '/servers', name: 'servers',
    component: () => import('../views/ServerView.vue')
  },
  {
    path: '/stats', name: 'stats',
    component: () => import('../views/StatsView.vue')
  },
  {
    path: `/stats/:username`,
    name: 'player-statistics',
    component: () => import('../views/StatsPlayerView.vue'), props: true
  },
  {
    path: '/:pathMatch(.*)*', name: 'error',
    component: () => import('../views/ErrorView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
